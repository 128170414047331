
  import { defineComponent, reactive, ref} from 'vue'
  export default defineComponent({
    setup() {
      const assign_array = reactive([])
      let before_assign:any = ref("['one', 'two']")
      let after_assign:any = ref("[1, 2]")
      let ok:any = ref("123")
      let object_assign:any = ref('{"a": 1, "b": 2}')
      let object_ok:any = ref("234")
      const onOK = () => {
        let ll  = JSON.parse(after_assign.value)
        let [one, two] = ll
        ok.value = one + ',' + two
      }
      const onOKObject = () => {
        let ll = JSON.parse(object_assign.value)
        let {a, b} = ll
        object_ok.value =  `a=${a} ,<br> b=${b}`
      }
      return {
        assign_array,
        before_assign,
        after_assign,
        onOK,
        ok,
        object_assign,
        onOKObject,
        object_ok
      }
    }
  })
